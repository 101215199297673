export const appConfig = {
  fallbackLanguage: 'en',
  supportedLanguages: [
    'cs',
    'sk',
    'en',
    'pl',
    'de',
    'af',
    'zu',
    'nl',
    'fr',
    'it',
    'pt',
    'ru',
    'es',
  ],
  endpoint: 'https://app.dev.speedlo.cloud',
  persistenceKey: process.env.REACT_APP_LOCAL_KEY || 'client@local',
  redirectOnFail: 'https://dev.speedlo.cloud/',
  sentryDSN: '',
  pageTitleSuffix: 'LOCAL',
  analytics: '',
  mapsEndpoint:
    'https://osm.speedlo.cloud/styles/klokantech-basic/{z}/{x}/{y}.png',
  mapsEndpointBase: 'https://osm.speedlo.cloud',
  mapsStyleUrl: 'https://osm.speedlo.cloud/styles/klokantech-basic/style.json',
}

if (process.env.REACT_APP_STAGE === 'dev') {
  Object.assign(appConfig, {
    persistenceKey: 'client@dev',
    sentryDSN: 'https://31756c9e430d450eaf3a44af1c1cd0f2@sentry.io/1354460',
    pageTitleSuffix: 'DEV',
    analytics: '',
  })
}

if (process.env.REACT_APP_STAGE === 'stage') {
  Object.assign(appConfig, {
    endpoint: 'https://app.stage.speedlo.cloud',
    persistenceKey: 'client@stage',
    sentryDSN: 'https://31756c9e430d450eaf3a44af1c1cd0f2@sentry.io/1354460',
    redirectOnFail: 'https://stage.speedlo.cloud/',
    pageTitleSuffix: 'STAGE',
    analytics: '',
  })
}

if (process.env.REACT_APP_STAGE === 'prod') {
  Object.assign(appConfig, {
    endpoint: 'https://c2e-p1.deep-vision.cloud',
    persistenceKey: 'client@prod',
    sentryDSN: 'https://31756c9e430d450eaf3a44af1c1cd0f2@sentry.io/1354460',
    pageTitleSuffix: 'speɘdlo',
    analytics: 'UA-4990100-13',
    redirectOnFail: 'https://speedlo.cz/',
  })
}
